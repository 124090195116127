/**
 * Component level data display. Currently used on home page.
 * @type {Readonly<({image: any, name: string, link: string, id: number})[]>}
 */

type TPopularSku = {
  id: number;
  name: string;
  img: string;
  link: string;
  seoName?: string;
  alt: string;
};

export const POPULAR_SKUS: TPopularSku[] = [
  {
    id: process.env.NODE_ENV === 'production' ? 1624 : 10562,
    name: 'General Handyman',
    link: 'general-handyman',
    img: '',
    alt: 'General Handyman',
  },
  {
    id: 1049,
    name: 'TV Mounting (33" - 60")',
    img: '/_nextassets_/images/skus/tvmount33-60.png',
    link: `tvmount-medium`,
    alt: 'Install TV Mountings',
  },
  {
    id: 1050,
    name: 'TV Mounting (61" or larger)',
    img: '/_nextassets_/images/skus/tvmount61plus.png',
    link: `tvmount-large`,
    alt: 'Install TV Mountings',
  },
  {
    id: 13,
    name: 'Computer Support',
    img: '/_nextassets_/images/skus/computer-with-sandy-background.png',
    link: `computer-repair-laptop-desktop`,
    alt: 'Get world class computer support',
  },
  {
    id: 200,
    name: 'Smart Security Cam Install',
    img: '/_nextassets_/images/skus/SmartCam.png',
    link: `smart-cam-installation`,
    alt: 'Expert Smart Security Cam Installs',
  },
  {
    id: 612,
    name: 'Smart Thermostat Install',
    img: '/_nextassets_/images/skus/Nest.png',
    link: `Smart-Thermostat-Installation`,
    alt: 'Expert Smart Thermostat Installs',
  },
];
