import React, {useEffect} from 'react';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import {useRouter} from 'next/router';
import {Button, BUTTON_THEMES, Icon} from 'ht-styleguide';

/* Components */
import SpaceY from '@components/UI/Space/Space.y-axis';
import TextSlide from '@scenes/HomePage/components/TextSlide';
import {DevlinkHomepageHero} from '@devlink/DevlinkHomepageHero';
import {DevlinkCategorySelectDropdown} from '@devlink/DevlinkCategorySelectDropdown';
import {DevlinkMenuItem} from '@devlink/DevlinkMenuItem';
import Maybe from '@components/Maybe';
import LazyComponent from '@components/LazyComponent';
import SkuItemBanner from 'src/components/Sku/SkuItemBanner';

/* Queries / Hooks / State  */
import useBrandingQueryCache from '@integrations/partners/branding/useBrandingQuery.cache';
import {useResponsiveIsMobile} from '@store/client/store.responsive';
import {useNavigationStoreActions} from '@store/client/store.navigation';

/* Constants / Utils */
import {speedTestPage, plansPath, skuCatPath, skuPath, meetTheTechsPath} from '@constants/paths';
import {POPULAR_SKUS} from '@constants/skus';
import categoryData from '@integrations/preval/skuCategoryRootData.preval';
import {toCommaSeparated} from '@utils/formatting';
import {prevented} from '@utils/event';
import {HT_INFO_SITELINKS} from '@constants/hellotech';
import {DYNAMIC_ONLINE_HEADER_LABELS} from '@scenes/HomePage/homepage.constants';

import {THomePageProps} from '@pages/index';
import {TServiceCategory} from '@scenes/ServicesCategoryPage/serviceCategoryPage.types';

import styles from './homepage.module.scss';
import {useRemovePartnerEffects} from '@hooks/useRemovePartnerEffects';

/* Dynamic Components */
const CustomerReviewsDynamic = dynamic(() => import('@components/UI/Reviews'), {ssr: false});
const DevlinkPopularSkusMinimalGridDynamic = dynamic(
  () => import('@devlink/DevlinkPopularSkusMinimalGrid').then(module => module.DevlinkPopularSkusMinimalGrid),
  {ssr: false},
);
const DevlinkPromoCardDynamic = dynamic(() => import('@devlink/DevlinkPromoCard').then(module => module.DevlinkPromoCard), {ssr: false});
const DevlinkContentBlockRightTextDynamic = dynamic(
  () => import('@devlink/DevlinkContentBlockRightText').then(module => module.DevlinkContentBlockRightText),
  {ssr: false},
);
const DevlinkPeaceOfMindBlockDynamic = dynamic(() => import('@devlink/DevlinkPeaceOfMindBlock').then(module => module.DevlinkPeaceOfMindBlock), {
  ssr: false,
});
const DevlinkPartnerLogosDynamic = dynamic(() => import('@devlink/DevlinkPartnerLogos').then(module => module.DevlinkPartnerLogos), {ssr: false});
const DevlinkStatsSectionDynamic = dynamic(() => import('@devlink/DevlinkStatsSection').then(module => module.DevlinkStatsSection), {ssr: false});
const DevlinkMeetTechSectionDynamic = dynamic(() => import('@devlink/DevlinkMeetTechSection').then(module => module.DevlinkMeetTechSection), {
  ssr: false,
});
const DevlinkSkuCategorySelectSectionDynamic = dynamic(
  () => import('@devlink/DevlinkSkuCategorySelectSection').then(module => module.DevlinkSkuCategorySelectSection),
  {ssr: false},
);
const DevlinkContentBlockCenterDynamic = dynamic(
  () => import('@devlink/DevlinkContentBlockCenter').then(module => module.DevlinkContentBlockCenter),
  {ssr: false},
);
const DevlinkStatsItemDynamic = dynamic(() => import('@devlink/DevlinkStatsItem').then(module => module.DevlinkStatsItem), {ssr: false});
const DevlinkSectionDynamic = dynamic(() => import('@devlink/DevlinkSection').then(module => module.DevlinkSection), {ssr: false});
const DevlinkTechItemDynamic = dynamic(() => import('@devlink/DevlinkTechItem').then(module => module.DevlinkTechItem), {ssr: false});
const DevlinkCardedMenuItemDynamic = dynamic(() => import('@devlink/DevlinkCardedMenuItem').then(module => module.DevlinkCardedMenuItem), {
  ssr: false,
});
const DevlinkPopularSkuItemDynamic = dynamic(() => import('@devlink/DevlinkPopularSkuItem').then(module => module.DevlinkPopularSkuItem), {
  ssr: false,
});
const HomePageBannerDynamic = dynamic(() => import('@scenes/HomePage/components/Banner').then(module => module.HomePageBanner), {ssr: false});
const DevlinkCtaStripDynamic = dynamic(() => import('@devlink/DevlinkCtaStrip').then(module => module.DevlinkCtaStrip), {ssr: false});

const HomePage = (props: THomePageProps) => {
  const {reviews, stats} = props;

  /* Client State */
  const isMobile = useResponsiveIsMobile();
  const {setNavigationMenuService} = useNavigationStoreActions();

  /* Hooks */
  const router = useRouter();
  const branding = useBrandingQueryCache();
  const removePartnerEffects = useRemovePartnerEffects();

  /* Constants */
  /**
   * Clear cookies & query cache on page load
   */
  useEffect(() => {
    removePartnerEffects();
  }, []);

  /* Methods */

  /* Path to Speed Test Page */
  const goToSpeedTest = () => {
    router.push(speedTestPage());
  };

  /* Path to Plan Page */
  const goToPlans = () => {
    router.push(plansPath());
  };

  /* Path to sku page */
  const goToSku = (skuSeo: string) => () => {
    router.push(skuPath(skuSeo));
  };

  /* Path to sku page */
  const goToSkuCategory = (catSeo: string) => () => {
    router.push(skuCatPath(catSeo));
  };

  /* Create dropdown items in hero */
  const onCreateMenu = (isHero: boolean = false) =>
    (categoryData as TServiceCategory[]).map(category => {
      if (!category.seoName || category.id === 26) return null;

      const Menu = isHero ? DevlinkMenuItem : DevlinkCardedMenuItemDynamic;
      const I = <Icon name={category.icon} className={styles.menuIcon} />;
      return (
        <Menu
          showImage={false}
          showImageSlot={true}
          imageSlot={I}
          key={category.id}
          action={{onClick: prevented(goToSkuCategory(category.seoName))}}
          label={category.name}
        />
      );
    });

  /* Creates the panels for the popular skus display */
  const createPopularSkusDisplay = () => {
    const PopularSkus = POPULAR_SKUS.map(sku => {
      if (!sku.img) return null;

      return (
        <DevlinkPopularSkuItemDynamic
          key={sku.id}
          showImageSource={false}
          showImageSlot={true}
          title={sku.name}
          action={{onClick: prevented(goToSku(sku.link as string))}}
          imageSlot={
            <div style={{margin: 'auto', maxWidth: '200px', height: '100%', width: '100%', position: 'relative'}}>
              <Image priority={true} layout="fill" src={sku.img} alt={sku.alt} quality={65} style={{objectFit: 'contain'}} />
            </div>
          }
        />
      );
    });

    return PopularSkus;
  };

  return (
    <>
      <input type="hidden" value="nextjs_homepage_e2e" />
      <HomePageBannerDynamic />
      <DevlinkHomepageHero
        deviceNameSlider={<TextSlide labels={DYNAMIC_ONLINE_HEADER_LABELS} />}
        categoryMenu={<DevlinkCategorySelectDropdown dropdownMenu={onCreateMenu(true)} />}
        showImageSource={false}
        showImageSlot={true}
        imageSlot={
          <div style={{position: 'relative', minWidth: '300px', display: 'flex', justifyContent: 'center'}}>
            {isMobile ? (
              ''
            ) : (
              <Image
                src="/_nextassets_/images/hero/femaleTechRemote.png"
                quality={70}
                priority={true}
                alt="Best tech solution for your home & office!"
                width={370}
                height={460}
              />
            )}
          </div>
        }
      />
      <SkuItemBanner theme="light" showBlobs={false} />
      <SpaceY classes="paddingTop-huge" />
      <LazyComponent minHeight={`${isMobile ? 400 : 244}px`} threshold={0.4} loader={false}>
        <DevlinkPopularSkusMinimalGridDynamic sectionTitle="Popular Services" skuItems={createPopularSkusDisplay()} />
      </LazyComponent>

      <Maybe condition={!branding?.hideMembershipLink}>
        <>
          <SpaceY classes="paddingTop-huge" />
          <LazyComponent minHeight={`${isMobile ? 200 : 135}px`} loader={false}>
            <DevlinkSectionDynamic
              sectionBody={
                <DevlinkPromoCardDynamic
                  imageSource="/_nextassets_/icons/ht-logo-gradient.svg"
                  showImageSource={true}
                  buttonSlot={
                    <Button className={styles.basicButton} onClick={goToPlans} theme={BUTTON_THEMES.GRADIENTBLUE}>
                      Learn More
                    </Button>
                  }
                />
              }
            />
          </LazyComponent>
        </>
      </Maybe>

      <SpaceY classes="paddingTop-huge" />
      <LazyComponent minHeight={`${isMobile ? 400 : 520}px`}>
        <DevlinkContentBlockRightTextDynamic
          showEyebrowText={false}
          mainTitle="Getting the speed you need?"
          bodyText="A fast connection is essential whether you’re working, playing, or relaxing. Use our speed test to make sure your network is giving you what you need."
          showButtonGroup={true}
          buttonGroup={
            <Button className={styles.basicButton} onClick={goToSpeedTest} theme={BUTTON_THEMES.PRIMARY}>
              Run Speed Test
            </Button>
          }
          showImageFit={true}
          imageFit="/_nextassets_/images/contentblock/speedtest_result.webp"
          showImageFill={false}
          hasBgColor={true}
        />
      </LazyComponent>

      <SpaceY classes="paddingTop-huge1" />
      <LazyComponent minHeight={`${isMobile ? 400 : 475}px`}>
        <DevlinkPeaceOfMindBlockDynamic />
      </LazyComponent>

      <SpaceY classes="paddingTop-huge1" />
      <LazyComponent minHeight={`${isMobile ? 170 : 110}px`} loader={false}>
        <DevlinkPartnerLogosDynamic />
      </LazyComponent>

      <SpaceY classes="paddingTop-huge1" />
      <LazyComponent minHeight={`${isMobile ? 170 : 200}px`}>
        <DevlinkStatsSectionDynamic
          statItems={
            <>
              <DevlinkStatsItemDynamic
                showGraphic={false}
                prominentText={toCommaSeparated(stats?.completedServicesCount || 749878)}
                subtext="Total Services Completed"
              />
              <DevlinkStatsItemDynamic
                showGraphic={true}
                graphicSource="/_nextassets_/icons/ht-star.png"
                prominentText={`${stats?.averageReviewsCount || 25}/5`}
                subtext="Average Customer Rating"
              />
            </>
          }
        />
      </LazyComponent>
      <DevlinkCtaStripDynamic
        title="Ready to get started?"
        showButton={true}
        buttonSlot={
          <Button onClick={prevented(setNavigationMenuService)} className={styles.basicButton} theme={BUTTON_THEMES.INVERTED}>
            Book A Service
          </Button>
        }
      />
      <Maybe condition={reviews.length > 0}>
        <LazyComponent minHeight="300px">
          <SpaceY classes="paddingTop-huge1" />
          <CustomerReviewsDynamic
            headerText="What our customers say"
            className="paddingX-medium"
            reviews={reviews}
            headerStyles="text-align-center"
            removeBackground={true}
            addShadow={false}
          />
        </LazyComponent>
      </Maybe>

      <LazyComponent minHeight={`${isMobile ? 500 : 500}px`}>
        <SpaceY classes="paddingTop-huge1" />
        <DevlinkMeetTechSectionDynamic
          techGrid={
            <>
              <DevlinkTechItemDynamic
                showImageSource={true}
                imageSource="/_nextassets_/images/techs/glenn.png"
                showImageSlot={false}
                techName="Glenn H."
                techSubtext="TV and AV Mounting Specialist"
                techBody="I love helping people solve their tech problems as well as helping them understand the issue so it doesn't happen again!"
              />
              <DevlinkTechItemDynamic
                showImageSource={true}
                imageSource="/_nextassets_/images/techs/jose.png"
                showImageSlot={false}
                techName="Jose C."
                techSubtext="Smart Home Specialist"
                techBody="I've always been interested in technology. It's great to be able to share what I love with people that need my help."
              />
              <DevlinkTechItemDynamic
                showImageSource={true}
                imageSource="/_nextassets_/images/techs/katie.png"
                showImageSlot={false}
                techName="Katie F."
                techSubtext="Mobile Specialist"
                techBody="It really makes me happy to see customers so excited to learn a new trick on their mobile devices! Definitely puts a smile on my face everytime."
              />
            </>
          }
          ctaLink={{onClick: () => router.push(meetTheTechsPath())}}
          ctaLabel="See More"
        />

        <DevlinkSkuCategorySelectSectionDynamic
          sectionTitle="How can we help?"
          subtext="We offer contactless online support and in-home services. Book a service today!"
          categoryMenu={onCreateMenu()}
        />

        <DevlinkContentBlockCenterDynamic
          showEyebrowText={false}
          headline="Become a Technician"
          showBodyText={true}
          bodyText="Get paid for helping people discover and enjoy technology."
          showButtons={true}
          buttons={
            <Button
              onClick={prevented(() => {
                window.location.href = HT_INFO_SITELINKS.techs.url;
              })}
              className={styles.basicButton}
              theme={BUTTON_THEMES.SECONDARY}
            >
              Learn More
            </Button>
          }
          hasLightBgColor={true}
          hasLightBgBlob={true}
        />
      </LazyComponent>
    </>
  );
};

export default HomePage;
