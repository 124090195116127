"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import * as _utils from "./utils";
import _styles from "./DevlinkMenuItem.module.css";

export function DevlinkMenuItem({
  as: _Component = _Builtin.Link,
  showImage = true,
  image = "",
  showImageSlot = true,
  imageSlot,
  label = "Menu Item",
  action = {},
}) {
  return (
    <_Component
      className={_utils.cx(_styles, "ht-menu-item")}
      button={false}
      block="inline"
      options={{
        href: "#",
      }}
      {...action}
    >
      {showImage ? (
        <_Builtin.Image
          className={_utils.cx(_styles, "ht-menu-item-img")}
          loading="lazy"
          width="auto"
          height="auto"
          alt=""
          src={image}
        />
      ) : null}
      {showImageSlot ? (
        <_Builtin.Block
          className={_utils.cx(_styles, "ht-menu-item-img")}
          tag="div"
        >
          {imageSlot}
        </_Builtin.Block>
      ) : null}
      <_Builtin.Block
        className={_utils.cx(_styles, "ht-menu-item-text")}
        tag="div"
      >
        {label}
      </_Builtin.Block>
    </_Component>
  );
}
