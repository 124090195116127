"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import * as _utils from "./utils";
import _styles from "./DevlinkHomepageHero.module.css";

export function DevlinkHomepageHero({
  as: _Component = _Builtin.Section,
  deviceNameSlider,
  categoryMenu,
  showImageSource = true,
  image = "https://uploads-ssl.webflow.com/5fece0d001923a6386f248d6/6604952904476fb7cfa3caf7_happy-tech%20(1).png",
  showImageSlot = true,
  imageSlot,
}) {
  return (
    <_Component
      className={_utils.cx(_styles, "ht-homepage-hero")}
      grid={{
        type: "section",
      }}
      tag="section"
    >
      <_Builtin.Block
        className={_utils.cx(_styles, "ht-container", "lg")}
        tag="div"
      >
        <_Builtin.Block
          className={_utils.cx(_styles, "ht-contained-hero")}
          tag="div"
        >
          <_Builtin.Block
            className={_utils.cx(_styles, "ht-contained-hero-content")}
            tag="div"
          >
            <_Builtin.Block
              className={_utils.cx(_styles, "txt-n000")}
              tag="div"
            >
              <_Builtin.Block tag="div">
                <_Builtin.Heading
                  className={_utils.cx(_styles, "ht-homepage-hero-title")}
                  tag="h1"
                >
                  {"In-Home & Online Support for your"}
                </_Builtin.Heading>
                <_Builtin.Block
                  className={_utils.cx(
                    _styles,
                    "ht-homepage-device-name-carousel"
                  )}
                  tag="div"
                >
                  {deviceNameSlider}
                </_Builtin.Block>
              </_Builtin.Block>
              <_Builtin.Block
                className={_utils.cx(_styles, "small")}
                tag="div"
              />
              <_Builtin.Block
                className={_utils.cx(_styles, "div-block-73")}
                tag="div"
              >
                <_Builtin.Paragraph
                  className={_utils.cx(_styles, "txt-body-lg", "txt-n000")}
                >
                  {
                    "The best tech solution, ready to help you - anytime, anywhere."
                  }
                </_Builtin.Paragraph>
              </_Builtin.Block>
            </_Builtin.Block>
            <_Builtin.Block
              className={_utils.cx(_styles, "small-2")}
              tag="div"
            />
            <_Builtin.Block tag="div">{categoryMenu}</_Builtin.Block>
          </_Builtin.Block>
          <_Builtin.Block
            className={_utils.cx(_styles, "ht-homepage-hero-img-container")}
            tag="div"
          >
            {showImageSource ? (
              <_Builtin.Block
                className={_utils.cx(_styles, "ht-homepage-hero-img")}
                tag="div"
              >
                <_Builtin.Image
                  className={_utils.cx(_styles, "ht-inner-img", "ht-fit")}
                  loading="lazy"
                  width="auto"
                  height="auto"
                  alt=""
                  src={image}
                />
              </_Builtin.Block>
            ) : null}
            {showImageSlot ? (
              <_Builtin.Block
                className={_utils.cx(_styles, "ht-homepage-hero-img")}
                tag="div"
              >
                {imageSlot}
              </_Builtin.Block>
            ) : null}
          </_Builtin.Block>
        </_Builtin.Block>
      </_Builtin.Block>
      <_Builtin.Block
        className={_utils.cx(_styles, "ht-bg-fill-container")}
        tag="div"
      >
        <_Builtin.Image
          className={_utils.cx(_styles, "ht-homepage-hero-blob")}
          loading="lazy"
          width="auto"
          height="auto"
          alt=""
          src="https://uploads-ssl.webflow.com/5fece0d001923a6386f248d6/6604a9049660b46d146767ff_homepage-hero-bg-blob.svg"
        />
      </_Builtin.Block>
    </_Component>
  );
}
