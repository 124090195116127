// @ts-nocheck
import React, {memo, useState, useEffect, forwardRef} from 'react';
import cn from 'classnames';
import Slick from 'react-slick';
import flow from 'lodash/flow';
import {Icon} from 'ht-styleguide';
import {Children} from 'types/base.types';
import styles from './carousel.module.scss';

/* Types */
type CarouselProps = {
  className: string;
  itemStyle: string;
  children: Children;
  resolutions: Object;
  equalHeight: boolean;
  infinite: boolean;
};

const convertResolutions = r =>
  Object.keys(r).reduce(
    (result, key) => [
      ...result,
      {
        breakpoint: +key,
        settings: r[key],
      },
    ],
    []
  );
const useResolutions = resolutions => {
  const [responsive, setResponsive] = useState(convertResolutions(resolutions || {}));
  useEffect(() => {
    setResponsive(convertResolutions(resolutions || {}));
  }, [resolutions]);
  return responsive;
};
// eslint-disable-next-line react/prop-types
const CarouselNext = ({className, currentSlide, slideCount, ...rest}) => (
  <span {...rest} className={cn(className, styles.next)}>
    <Icon name="v2-skinny-arrow-right" data-testid="carousel-icon-right" />
  </span>
);
// eslint-disable-next-line react/prop-types
const CarouselPrevious = ({className, currentSlide, slideCount, ...rest}) => (
  <span {...rest} className={cn(className, styles.previous)}>
    <Icon name="v2-skinny-arrow" data-testid="carousel-icon-left" />
  </span>
);
const baseSettings = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <CarouselNext />,
  prevArrow: <CarouselPrevious />,
};
const Carousel = (
  {className, itemStyle, children, resolutions, equalHeight, infinite = true, currentSlide, slideCount, ...rest}: CarouselProps,
  ref
) => {
  const responsive = useResolutions(resolutions);

  return (
    <Slick
      ref={ref}
      className={cn(styles.carousel, {[styles.equalHeight]: equalHeight, [styles.nonInfinite]: !infinite}, className)}
      {...baseSettings}
      responsive={responsive}
      {...rest}
    >
      {children.map((child, index) => (
        <div key={`carousel${index}`} className={cn(styles.item, itemStyle)}>
          {child}
        </div>
      ))}
    </Slick>
  );
};

export default flow(forwardRef, memo)(Carousel);
