import Cookies from 'js-cookie';

import {removePhoneNumber} from '@utils/cookies/phone';
import usePartnerQueryRemove from '@integrations/partners/usePartnerQuery.remove';
import {removeFieldSalesCookie} from '@utils/cookies/fieldSalesCookie';
import {removeDirectBookingTechData} from '@utils/cookies/directBookingCookie';

/**
 * @description: This hook is used to remove the effects of the partner from the user
 *               Note: Some of these cookie are not dropped by the nextjs app, but rather the client app.
 *                     But, we still have to delete them.
 */

export const useRemovePartnerEffects = () => {
  const removePartnerCache = usePartnerQueryRemove();

  const removePartnerEffects = () => {
    /* Remove  partner cache, phone */
    removePhoneNumber();
    removePartnerCache();
    /* Remove Field Sales Cookie */
    removeFieldSalesCookie();
    /* Remove Direct Booking TechData: When we do directbooking on this app */
    removeDirectBookingTechData();
    /* Remove current apps cookies */
    Cookies.remove('isPartnerRelated');
    Cookies.remove('partnerLanding');
  };

  return removePartnerEffects;
};
