// Libraries
import React, {memo} from 'react';
import cn from 'classnames';
// Components
import {Grid, Icon} from 'ht-styleguide';
import Banner from '@components/UI/Banner';
import Carousel from '@components/UI/Carousel';
// Styles
import styles from './skuitembanner.module.scss';
// Types
interface SkuItemBannerProps {
  theme?: 'default' | 'light';
  containerClasses?: string;
  itemStyle?: string;
  showBlobs?: boolean;
  textColor?: string;
  toolkitLabel?: string;
  clockLabel?: string;
  checkmarkLabel?: string;
}

const SkuItemBanner: React.FC<SkuItemBannerProps> = ({
  containerClasses,
  itemStyle,
  textColor = '',
  showBlobs = true,
  theme = 'default',
  toolkitLabel = 'Thousands of Techs Nationwide',
  clockLabel = 'Service as Soon as Today',
  checkmarkLabel = 'Don’t Pay Until It’s Done',
}) => {
  // Styles
  const bannerStyles = cn(styles.banner, containerClasses, styles[theme]);
  const itemStyles = cn(
    styles.item,
    {
      white: !textColor,
      [textColor]: !!textColor,
    },
    styles[theme]
  );
  const slideStyles = cn(styles.slide, itemStyle);
  /*
        We need to set up outside the render or else Slick will somehow pick up null children (if
        that makes sense)
      */
  const slides = [
    toolkitLabel && (
      <h5 className={itemStyles} key="toolkit">
        <Icon name="toolkit" className={styles.toolkit} /> {toolkitLabel}
      </h5>
    ),
    clockLabel && (
      <h5 className={itemStyles} key="clock">
        <Icon name="flying-clock" className={styles.service} /> {clockLabel}
      </h5>
    ),
    checkmarkLabel && (
      <h5 className={itemStyles} key="check">
        <Icon name="checkmark-broken-circle" className={styles.check} /> {checkmarkLabel}
      </h5>
    ),
  ].filter(s => Boolean(s));
  const slidesToShow = slides.length;
  return (
    <Banner className={bannerStyles} showBlobs={showBlobs}>
      <Grid.FullWidth>
        <Carousel
          className={styles.carousel}
          autoplay
          autoplaySpeed={4000}
          speed={800}
          slidesToShow={slidesToShow}
          slidesToScroll={slidesToShow}
          itemStyle={slideStyles}
          resolutions={{
            1020: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          }}
        >
          {slides}
        </Carousel>
      </Grid.FullWidth>
    </Banner>
  );
};

export default memo(SkuItemBanner);
