import React from 'react';
import {GetStaticProps} from 'next';

/* Components */
import {getLayout} from '@components/PageLayouts/Layout/Standard';
import Home from '@scenes/HomePage';

/* Constants */
import {PAGE_TYPE_HOME} from '@constants/pages';

/* Apis */
import {reviews, skus} from '@api/apis';

/* Types */
import {Children} from 'types/base.types';
import {Review} from 'types/reviews.types';
import {SEVEN_DAYS} from '@constants/CacheRevalidate';

export type THomePageProps = {
  reviews: Review[];
  stats: {
    completedServicesCount: number;
    averageReviewsCount: number;
  };
};

function HomePage(props: THomePageProps): Children {
  return <Home reviews={props.reviews} stats={props.stats} />;
}

HomePage.pageAttributes = {page: PAGE_TYPE_HOME};
HomePage.Layout = getLayout({promo: true, footer: true, partnerEnabled: false, page: PAGE_TYPE_HOME});

export const getStaticProps: GetStaticProps = async context => {
  /* Get customer reviews data */
  const r = await reviews.list();
  const data = await skus.totalCompletedServicesAndClientRating();

  return {
    props: {
      reviews: r?.data?.reviews || [],
      stats: data?.data?.stats,
    },
    revalidate: SEVEN_DAYS,
  };
};

export default HomePage;
