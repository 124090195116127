import {useQueryClient} from '@tanstack/react-query';
import {isServerSide} from '@utils/environment';
import {PartnerQueryKey} from '@integrations/partners/usePartnerQuery.set';

const usePartnerQueryRemove = () => {
  const queryClient = useQueryClient();

  const removePartnerCache = () => {
    if (!isServerSide()) {
      queryClient.removeQueries({queryKey: PartnerQueryKey, exact: true});
    }
  };

  return removePartnerCache;
};

export default usePartnerQueryRemove;
