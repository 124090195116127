import React, {useState, useEffect} from 'react';
import {useTransition, animated} from '@react-spring/web';
import cn from 'classnames';

import styles from '@scenes/HomePage/components/TextSlide/textslide.module.scss';

/**
 * TextSlide component
 * Could break it out to a more global intent. But no need for now.
 * @returns {React.JSX.Element}
 * @constructor
 */
type TTextSlide = {
  id: number;
  title: string;
};
const TextSlide = ({labels}: {labels: TTextSlide[]}) => {
  if (!labels) return null;
  const [index, setIndex] = useState(0);

  const transitions = useTransition([labels[index]], {
    from: {opacity: 0, transform: 'translate3d(10%, 0 ,0)', display: 'inline'},
    enter: {opacity: 1, transform: 'translate3d(0%, 0, 0)'},
    leave: {opacity: 0, transform: 'translate3d(0%, 0, 0)'},
  });
  useEffect(() => {
    const interval = setInterval(() => {
      setIndex(state => (state + 1) % labels.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);
  return (
    <div className={styles.animatedParentWrapper}>
      <span className={cn(styles.dynamicText, 'h2')}>
        {transitions((style, item) => (
          <animated.div key={item.id} style={{...style, position: 'absolute'}}>
            {item.title}
          </animated.div>
        ))}
      </span>
    </div>
  );
};

export default TextSlide;
