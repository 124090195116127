import React, { memo } from "react";
import cn from "classnames";
import { Children, IHash} from 'types/base.types';
import styles from "./banner.module.scss";

/* Types */
export type BannerProps = {
  children: Children;
  className: string;
  showBlobs?: boolean;
  style?: IHash<string>;
}

const Banner = ({ children, className, showBlobs, style = {} }: BannerProps) => (
  <div
    data-testid="banner"
    className={cn(styles.banner, className, { [styles.blobs]: showBlobs })}
    style={style}
  >
    {children}
  </div>
);

export default memo(Banner);
