import Cookies from 'js-cookie';
import get from 'lodash/get';
import {Logger} from '@utils/logger';

const FIELD_SALES_COOKIE = '_field_sales';
const EXPIRATION_DAYS = 1;

export const localStorageTypeUtil = (method = 'get', agentType: string) => {
  try {
    return window.localStorage[`${method}Item`](FIELD_SALES_COOKIE, agentType);
  } catch (err) {
    return Logger("Field Sales")('Error loading localStorage')
  }
}

/*
* Sets the following fields:
* agentEmail: optional
* agentId: required
* agentType: required
* */
export const removeFieldSalesCookie = () => Cookies.remove(FIELD_SALES_COOKIE);

export const setFieldSalesCookie = (data: any) => {
  removeFieldSalesCookie();
  localStorageTypeUtil('set', data.agentType);
  Cookies.set(FIELD_SALES_COOKIE, JSON.stringify(data), { expires: EXPIRATION_DAYS });
}

export const getFieldSalesCookie = () => {
  const value = Cookies.get(FIELD_SALES_COOKIE);

  return value ? JSON.parse(value) : null;
};

export const getFieldSalesCookieById = () => {
  const cookie = getFieldSalesCookie();
  return get(cookie, 'agentId', '');
};

export const getFieldSalesCookieByEmail = () => {
  const cookie = getFieldSalesCookie();
  return get(cookie, 'agentEmail', '');
};

export const getFieldSalesCookieByType = () => {
  const storage = localStorageTypeUtil('get', FIELD_SALES_COOKIE);
  const cookie = getFieldSalesCookie();

  return get(cookie, 'agentType') ||  storage;
};
